import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { KProductCardType } from '~/lib/data-contract';
import { breakpoints } from '~/theme';
import { getFluidValue } from '~/theme/utils/getClamp';

const cardWrapperStyles = css({
    height: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    gap: 20,
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
});

export const StyledCardWrapper = styled.article<{ productType: KProductCardType }>(
    () => ({
        ...cardWrapperStyles,
    }),
    ({ theme }) => ({
        overflow: 'hidden',
        borderRadius: 'clamp(2.4rem, 3.125vw, 4.5rem) clamp(0.8rem, 1.0416666666666665vw, 1.5rem)',
        boxShadow: theme.elevations.card,
        [breakpoints.lg]: {
            minHeight: 550,
        },
    }),
);

export const StyledCardInner = styled.div<{ productType: KProductCardType }>(
    () => ({
        ...cardWrapperStyles,
    }),
    () => ({
        padding: 0,
    }),
    ifProp({ productType: KProductCardType.REGULAR }, ({ theme }) => ({
        bottom: 0,
        cursor: 'pointer',
        width: '100%',
        padding: `${theme.spaces[7]} ${theme.spaces[4]} ${theme.spaces[4]}`,
        [breakpoints.sm]: {
            padding: `${theme.spaces[7]} ${theme.spaces[5]} ${theme.spaces[5]}`,
        },
        [breakpoints.md]: {
            padding: theme.spaces[7],
        },
    })),
);

export const StyledImageWrapper = styled.div<{ productType: KProductCardType }>(
    ({ productType, theme }) => ({
        position: productType === KProductCardType.REGULAR ? 'relative' : 'absolute',
        top: productType === KProductCardType.REGULAR ? 'auto' : 0,
        left: productType === KProductCardType.REGULAR ? 'auto' : 0,
        width: '100%',
        [breakpoints.md]: {
            padding: theme.spaces[4],
        },
    }),
    ifProp({ productType: KProductCardType.TEXT_CTA }, { display: 'none' }),
    ifProp({ productType: KProductCardType.REGULAR }, () => ({
        flexGrow: 1,
        alignItems: 'flex-end',
        display: 'flex',

        '> * ': {
            width: '100%',
        },
    })),
);

export const StyledHoverImage = styled.div(({ theme }) => ({
    position: 'absolute',

    marginLeft: `-${theme.spaces[4]}`,

    height: `calc( 100% + ${getFluidValue(32)})`,
    width: `calc( 100% + ${getFluidValue(32)})`,

    [breakpoints.sm]: {
        marginLeft: `-${theme.spaces[5]}`,
        width: `calc( 100% + ${getFluidValue(40)})`,
    },

    [breakpoints.md]: {
        marginLeft: `-${getFluidValue(48)}`,
        marginBottom: `-${getFluidValue(16)}`,
        width: `calc( 100% + ${getFluidValue(64)})`,
    },
}));

export const StyledContentWrapper = styled.div<{ productType: KProductCardType }>(
    {
        display: 'flex',
        flexDirection: 'column',
    },
    ifProp({ productType: KProductCardType.REGULAR }, ({ theme }) => ({
        bottom: 0,
        width: '100%',
        paddingTop: theme.spaces[3],
    })),
    ifProp({ productType: KProductCardType.TEXT_IMAGE_CTA }, ({ theme }) => ({
        top: 0,
        width: '100%',
        height: '100%',
        padding: `${theme.spaces[8]} 50px`,
        backgroundColor: 'rgb(0 0 0 / 40%);',
    })),
    ifProp({ productType: KProductCardType.TEXT_CTA }, ({ theme }) => ({
        top: 0,
        width: '100%',
        height: '100%',
        padding: `${theme.spaces[8]} 50px`,
    })),
);

export const StyledMetaWrapper = styled.div({
    display: 'flex',
});

export const StyledSpacer = styled.div({
    flex: 1,
});

export const StyledCTAWrapper = styled.div(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: `${theme.spaces[8]} 50px`,
}));

export const StyledCardLink = styled.a(
    () => ({
        ...cardWrapperStyles,
    }),
    () => ({
        textDecoration: 'none',
    }),
);

export const StyledProductDetailsWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spaces[3],
    alignItems: 'flex-end',

    [breakpoints.sm]: {
        alignItems: 'center',
    },
}));

export const StyledProductDetails = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'column',
    gap: theme.spaces[3],

    [breakpoints.sm]: {
        flexFlow: 'row',
    },
}));

export const StyledProductDetail = styled.span(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey60,
    '> p': {
        textTransform: 'uppercase',
        padding: `0 0 0 ${theme.spaces[1]}`,
    },
}));

export const StyledTooltip = styled.div(({ theme }) => ({
    position: 'absolute',
    top: theme.spaces[7],
    left: theme.spaces[7],
    zIndex: 9,
    color: theme.colors.white,

    'div > span': {
        color: theme.colors.black,
    },
}));
