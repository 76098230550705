import { Product } from '../../model';

export const getProductImage = (product: Product) => {
    const mainVariantImage = product?.allVariants?.find((item) => item?.data?.IsMainVariant?.value)
        ?.data?.ImageCAD1?.value;
    const fallbackVariantImage = product?.allVariants?.find(
        (item) => !!item?.data?.ImageCAD1?.value,
    )?.data?.ImageCAD1?.value;

    return mainVariantImage ?? fallbackVariantImage;
};

export const getProductHoverImage = (product: Product) => {
    const productPhoto = product.data.ProductPhotos && JSON.parse(product.data.ProductPhotos.value);

    return productPhoto && productPhoto[0]?.Url;
};
