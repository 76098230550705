import fallbackImage from '$assets/fallback-product-card.svg';
import UserLight from '$icons/user-light.svg';
import Size from '$icons/size.svg';
import UsersLight from '$icons/users-light.svg';
import { CallToAction, Icon, Text, Tooltip } from '$shared/components';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, memo, useCallback, useEffect, useState } from 'react';
import { useSwiperSlide } from 'swiper/react';
import { KProductCardModel, KProductCardType, Link as LinkType } from '~/lib/data-contract';
import { queries, theme } from '~/theme';
import { ColorDots } from '../ColorDots/ColorDots';
import { Image } from '../Image';
import {
    StyledCTAWrapper,
    StyledCardInner,
    StyledCardLink,
    StyledCardWrapper,
    StyledContentWrapper,
    StyledImageWrapper,
    StyledMetaWrapper,
    StyledProductDetail,
    StyledProductDetails,
    StyledProductDetailsWrapper,
    StyledSpacer,
} from './styled';
import InfoCircle from '$icons/info-circle-round.svg';
import { useTranslation } from '~/shared/utils';
import { HoverImage } from './HoverImage';

const getTooltip = (text: string) => {
    if (!text) return;
    return (
        <Tooltip content={text} delayDuration={0} contentWidth={370}>
            <Icon>
                <InfoCircle />
            </Icon>
        </Tooltip>
    );
};

export const _KProductCard = ({
    type = KProductCardType.REGULAR,
    title,
    subtitle,
    buttonText,
    price,
    productId,
    tags,
    colors,
    imageUrl,
    url,
    ageGroup,
    userCapacity,
    spaceRequired,
    onNavigate,
    hoverImageUrl,
    hoverImageQuality,
    children,
    ...rest
}: KProductCardModel & React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
    const colorsToShow = 5;
    const router = useRouter();
    const { translate } = useTranslation();
    const getTitleVariant = () => {
        switch (type) {
            case KProductCardType.REGULAR:
                return undefined;
            case KProductCardType.TEXT_CTA:
                return 'display4';
            case KProductCardType.TEXT_IMAGE_CTA:
                return 'display2';
            default:
                return 'body';
        }
    };

    const getTitleStyle = () => {
        switch (type) {
            case KProductCardType.REGULAR:
                return {
                    fontSize: 'clamp(2.30rem, 1.1111111111111112vw, 1.6rem)',
                    lineHeight: 1,
                    paddingBottom: theme.spaces[1],
                    color: theme.colors.black100,
                };
            case KProductCardType.TEXT_CTA:
                return {};
            case KProductCardType.TEXT_IMAGE_CTA:
                return {
                    color: theme.colors.white100,
                };
            default:
                return {};
        }
    };

    const getCallToAction = () => {
        if (type === KProductCardType.TEXT_CTA) {
            return {
                url,
                text: buttonText,
                title: buttonText,
                iconName: 'arrow-right',
            } as LinkType;
        } else {
            return {
                url,
                text: buttonText,
                title: buttonText,
                iconName: 'arrow-right',
                shade: 'light',
                variation: 'Transparent',
            } as LinkType;
        }
    };
    const swiperSlide = useSwiperSlide();

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = useCallback(() => {
        hoverImageUrl && setIsHovered(true);
    }, [hoverImageUrl]);

    const handleMouseLeave = useCallback(() => {
        hoverImageUrl && setIsHovered(false);
    }, [hoverImageUrl]);

    useEffect(() => {
        const onRouteChange = (newUrl: string) => {
            if (newUrl == url && onNavigate) {
                onNavigate();
            }
        };
        router.events.on('routeChangeStart', onRouteChange);
        return () => {
            router.events.off('routeChangeStart', onRouteChange);
        };
    }, [router.events]);

    return (
        <StyledCardWrapper
            productType={type}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...rest}
        >
            <NextLink href={url as string} prefetch={false} legacyBehavior>
                <StyledCardInner productType={type}>
                    <ConditionalLink type={type} url={url}>
                        <StyledImageWrapper productType={type}>
                            <Image
                                alt={title}
                                src={imageUrl ? imageUrl : fallbackImage}
                                objectFit={'contain'}
                                layout="responsive"
                                quality="70"
                                width="400"
                                height="300"
                                sizes={`${queries.xs} 50vw, ${queries.contentMax} 375px, ${queries.md} 33vw, ${queries.sm} 50vw,`}
                                disableSkeleton={true}
                                // Preload images if on next slide. Only works if 1 image is shown.
                                priority={swiperSlide?.isNext || swiperSlide?.isPrev}
                            />
                            {hoverImageUrl && isHovered && (
                                <HoverImage
                                    imageQuality={hoverImageQuality}
                                    hoverImageUrl={hoverImageUrl}
                                />
                            )}
                        </StyledImageWrapper>
                        <StyledContentWrapper productType={type}>
                            {subtitle && type === KProductCardType.TEXT_IMAGE_CTA && (
                                <Text
                                    variant="caption"
                                    style={{
                                        color: 'rgb(255 255 255 / 50%)',
                                        paddingBottom: theme.spaces[4],
                                    }}
                                >
                                    {subtitle}
                                </Text>
                            )}
                            {type === KProductCardType.REGULAR && (
                                <StyledMetaWrapper>
                                    {productId && (
                                        <Text
                                            variant="bodySm"
                                            style={{ lineHeight: 1.4, color: theme.colors.grey60 }}
                                        >
                                            {productId}
                                        </Text>
                                    )}
                                    <StyledSpacer />
                                    {tags &&
                                        tags.map((tag, index) => (
                                            <Text
                                                variant="bodySm"
                                                style={{
                                                    lineHeight: 1.4,
                                                    color: theme.colors.grey60,
                                                }}
                                                key={index}
                                            >
                                                {tag}
                                            </Text>
                                        ))}
                                </StyledMetaWrapper>
                            )}
                            {title && (
                                <Text variant={getTitleVariant()} style={getTitleStyle()}>
                                    {title}
                                </Text>
                            )}
                            {type === KProductCardType.REGULAR && price && (
                                <Text
                                    style={{
                                        fontSize: 'clamp(2.30rem, 1.1111111111111112vw, 1.6rem)',
                                        lineHeight: 1,
                                        color: theme.colors.grey60,
                                        display: 'flex',
                                        gap: theme.spaces[1],
                                        alignItems: 'baseline',
                                    }}
                                >
                                    {price}{' '}
                                    {getTooltip(translate('Kompan.ProductDetail.PriceDisclaimer'))}
                                </Text>
                            )}

                            {type === KProductCardType.REGULAR && (
                                <StyledProductDetailsWrapper>
                                    <StyledProductDetails>
                                        {ageGroup && (
                                            <StyledProductDetail>
                                                <Icon size="md">
                                                    <UserLight />
                                                </Icon>
                                                <Text
                                                    variant="bodySm"
                                                    style={{
                                                        color: theme.colors.grey60,
                                                    }}
                                                >
                                                    {ageGroup}
                                                </Text>
                                            </StyledProductDetail>
                                        )}

                                        {spaceRequired && (
                                            <StyledProductDetail>
                                                <Icon size="md">
                                                    <Size />
                                                </Icon>
                                                <Text
                                                    variant="bodySm"
                                                    style={{
                                                        color: theme.colors.grey60,
                                                    }}
                                                >
                                                    {spaceRequired}
                                                </Text>
                                            </StyledProductDetail>
                                        )}
                                        {userCapacity && (
                                            <StyledProductDetail>
                                                <Icon size="md">
                                                    <UsersLight />
                                                </Icon>
                                                <Text
                                                    variant="bodySm"
                                                    style={{
                                                        color: theme.colors.grey60,
                                                    }}
                                                >
                                                    {userCapacity}
                                                </Text>
                                            </StyledProductDetail>
                                        )}
                                    </StyledProductDetails>
                                    {!!colors?.length && (
                                        <ColorDots colors={colors} maxColors={colorsToShow} />
                                    )}
                                </StyledProductDetailsWrapper>
                            )}
                            {buttonText && url && type !== KProductCardType.REGULAR && (
                                <StyledCTAWrapper>
                                    <CallToAction callToAction={getCallToAction()} />
                                </StyledCTAWrapper>
                            )}
                        </StyledContentWrapper>
                    </ConditionalLink>
                </StyledCardInner>
            </NextLink>
            {children}
        </StyledCardWrapper>
    );
};

const ConditionalLink = memo(
    ({
        children,
        url,
        type,
    }: {
        children: ReactNode | ReactNode[];
        url?: string;
        type: KProductCardType;
    }) => {
        if (!url || type != KProductCardType.REGULAR) {
            return <>{children}</>;
        }
        return <StyledCardLink href={url}>{children}</StyledCardLink>;
    },
);

export const KProductCard = memo(_KProductCard);
KProductCard.displayName = 'KProductCard';
