import { ParsedUrlQuery } from 'querystring';
import { DropdownItem } from '~/shared/components';
import { SelectedFacet, ProductsStorePaginationType } from '../../model';
import { isSSR } from '~/shared/utils';
import { isRangesFacet } from './isRangesFacet';
import { RelewiseRangesType } from '../requests';

export const productStoreUrlHelper = () => {
    /**
     *
     * @param facets The facets selected by the user on the PLP page
     * @returns
     */

    const mapFiltersToParams = (facets: SelectedFacet[]) => {
        const selectedFilters = facets
            .map((facet) => {
                if (!facet.items.length) return;
                const values = facet.items.map((item) => {
                    return item.value;
                });
                if (!values?.length) return;

                if (isRangesFacet(facet?.items[0])) {
                    const valuesMap = values
                        .map((value) => {
                            return [
                                (value as RelewiseRangesType)?.lowerBoundInclusive,
                                (value as RelewiseRangesType)?.upperBoundExclusive,
                            ];
                        })
                        .join('|');
                    return `${facet.attribute}:ranges=${valuesMap}`;
                }
                return `${facet.attribute}:${values.join('|')}`;
            })
            .filter((x) => x) as string[];
        return selectedFilters.length ? `${selectedFilters.join(';')}` : null;
    };

    const mapFiltersFromParams = (query: ParsedUrlQuery) => {
        const filters = query?.filters as string;
        if (!filters) return [];
        const filtersArray = filters
            .split(';')
            .map((filterString) => {
                const [attribute, choices] = filterString.split(':');
                if (!choices?.length) return;
                if (choices.startsWith('ranges=')) {
                    const ranges = choices.replace('ranges=', '');
                    return {
                        attribute: attribute,
                        items: ranges.split('|').map((choice) => {
                            return {
                                label: '',
                                value: {
                                    lowerBoundInclusive: choice.split(',').shift(),
                                    upperBoundExclusive: choice.split(',').pop(),
                                },
                            };
                        }) as DropdownItem[],
                    };
                }
                return {
                    attribute: attribute,
                    items: choices.split('|').map((choice) => {
                        return {
                            label: '',
                            value: choice,
                        };
                    }) as DropdownItem[],
                };
            })
            .filter((item) => item?.attribute);

        return filtersArray as SelectedFacet[];
    };

    const getPageFromQuery = (query: ParsedUrlQuery) => {
        const { page = 1 } = query;
        return parseInt(`${page}`) - 1;
    };

    /**
     *
     * @param customUrl Provide a custom URL if used in SSR context. Otherwise uses window.location
     * @param itemsPerPage Provide itemsPerPage to the pagination object returned
     * @param paginationType Provide paginationType to the pagination object returned
     * @returns A collection of data used for the PLP state
     */

    type params = {
        pagination: {
            [key: string]: string | number;
        };
        category: string;
        query: string;
        sortKey: string | null;
        sortOrder: string | null;
    };

    const getStateFromUrl = (
        customUrl?: string | null,
        itemsPerPage?: number,
        paginationType?: ProductsStorePaginationType,
    ) => {
        const ssrSafeUrl = isSSR ? '' : window.location.href;
        const url = customUrl ?? ssrSafeUrl;
        const queryPart = url.split('?').slice(1);
        const urlParams = new URLSearchParams(queryPart.join());
        const pagination: { [key: string]: string | number } = {};
        const params: params = {
            pagination,
            category: '',
            query: '',
            sortKey: null,
            sortOrder: null,
        };

        if (itemsPerPage) {
            params.pagination.itemsPerPage = itemsPerPage;
        }
        if (paginationType) {
            params.pagination.type = paginationType;
        }

        const category = urlParams.get('categories');
        if (category) {
            params.category = category;
        }

        const query = urlParams.get('search');
        if (query) {
            params.query = query || '';
        }

        const page = urlParams.get('page') ?? '1';
        params.pagination.page = parseInt(page) - 1;

        const sortKey = urlParams.get('sortKey');
        const sortOrder = urlParams.get('sortOrder');
        if (sortKey && sortOrder) {
            params.sortKey = sortKey;
            params.sortOrder = sortOrder;
        }

        return params;
    };

    /**
     *
     * @param facets The facets selected for product list page
     * @returns The key used for react-query to identify the active facets
     */
    const getFacetQueryKey = (facets: SelectedFacet[]) => {
        const filteredFacets = facets
            .filter((facet) => facet.items?.length != 0)
            .sort((a, b) => a.attribute.localeCompare(b.attribute))
            .map((facet) => {
                return {
                    attribute: facet.attribute,
                    items: facet.items.map((item) => {
                        if (isRangesFacet(item)) {
                            return {
                                lowerBoundInclusive: `${
                                    (item.value as RelewiseRangesType).lowerBoundInclusive
                                }`,
                                upperBoundExclusive: `${
                                    (item.value as RelewiseRangesType).upperBoundExclusive
                                }`,
                            };
                        }
                        return `${item.value}`;
                    }),
                };
            });
        return JSON.stringify(filteredFacets);
        return facets
            .map((facet) => {
                const items = facet.items
                    .map((item) => item.value)
                    .sort()
                    .join(',');
                return `${facet.attribute}-${items}`;
            })
            .join(';');
    };

    return {
        getPageFromQuery,
        mapFiltersToParams,
        mapFiltersFromParams,
        getStateFromUrl,
        getFacetQueryKey,
    };
};
